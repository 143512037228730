import React, { useState, useEffect } from "react";

import { Routes, Route, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { studioUser, saveBudget, saveBudget2 } from "../helpers/helpers";
import { FormChat } from "./FormChat/FormChat";
import { InitialsButtons } from "./FormChat/InitialsButtons";
import { PageLoading } from "./PageLoading/PageLoading";
import { aplyClass } from "./utils/styles";
import { initailOrImage } from "./FormChat/utils/initalImage.utils";
import { DataTattoo } from "./utils/defaultComponents/TattooComponents";
import { DataPiercing } from "./utils/defaultComponents/PiercingComponents";
import { DataAll } from "./utils/defaultComponents/FormChatComponents";
import { NewTattoo } from "./FormChat/Components/NewFormTattoo/NewTattoo";
import dontknowImage from "../assets/images/dontknow.png";

export default function TemplateRoute({ language, setLanguage, errorAlert, setErrorAlert, error }) {
  const { text } = useParams();
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState([]);
  const [budget_color, setColor] = useState("");
  const [medias, setMedias] = useState([]);
  const [cities, setCities] = useState([]);
  const [employeds, setEmployeds] = useState([]);
  const [typeJewelry, setTypeJewelry] = useState([]);
  const [styles, setStyles] = useState([]);
  const [budgetCount, setBudgetCount] = useState(null);
  const [arrayComponent, setComponents] = useState([]);
  const [arrayComponentInitial, setArrayComponentInitial] = useState([]);
  const [arrayComponentServices, setComponentsServices] = useState([]);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [formData, setForm] = useState([]);
  const [formDataServices, setFormServices] = useState([]);
  const [formDataInfo, setFormInfo] = useState([]);
  const [checkTerms, setCheckTerms] = useState(false);
  const [contact, setContact] = useState(null);
  const [city, setCity] = useState();
  const [emailOrPhone, setEmailOrPhone] = useState(false);
  const [phoneCity, setPhoneCity] = useState(null);
  const [emailCity, setEmailCity] = useState(null);
  const [typeArray, setTypeArray] = useState(0);
  const [query, setQuery] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const [checkTermsAnotherQuery, setCheckTermsAnotherQuery] = useState(false);
  const [optionButtonServices, setOptionButtonServices] = useState(null);
  const [showWriting, setShowWriting] = useState(false);
  const [disabledButtonSendEmail, setDisabledButtonSendEmail] = useState(true);
  const [status, setStatus] = useState(null);
  const [disabledTextAreaAnotherQuery, setDisabledTextAreaAnotherQuery] =
    useState(true);
  const [notImages, setNotImages] = useState(false);
  // esto hay que borrarlo cuando se quite el componente de prueba de initalButtons
  const [initialButtons, setInitialButtons] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState(null);
  let newEmployed = {
    name: "Prefiero asesorarme",
    image: dontknowImage,
  };
  let dataPiercing = DataPiercing();
  let dataTattoo = DataTattoo();
  useEffect(() => {
    if (errorAlert === true) {
      setComponents([])
    }

  }, [errorAlert]);
  useEffect(() => {

    getData();

  }, []);

  const getData = async (e) => {
    let resp = await studioUser(text);
    if (resp.status === 200) {
      setLoading(false);
    }
    setUser(resp.data?.result.resultUser[0]);
    setMedias(resp.data?.result.resultMedia[0]);
    setCities(resp.data?.result.resultCity);
    setCities(resp.data?.result.resultCity);
    if (resp.data?.result.resultEmployeds.length !== 0) {
      resp.data.result.resultEmployeds.unshift(newEmployed);
    }
    setEmployeds(resp.data?.result.resultEmployeds);
    setTypeJewelry(resp.data?.result.resultTypeJewelry);
    setFormServices(
      resp?.data?.result?.resultCustomFormComponents[0]
        ?.components_chat_services
    );
    if (resp?.data?.result?.resultCustomFormStyles?.length !== 0) {
      setStyles(resp?.data?.result?.resultCustomFormStyles);
    }
    setBudgetCount(resp.data?.budget.budgetsCount);
    let data;

    if (resp.data?.result.resultUser[0].id_rol !== 4) {
      data = dataTattoo;
    } else {
      data = dataPiercing;
    }
    const components =
      user.id_rol !== 4
        ? resp?.data?.result?.resultCustomFormComponents[0]?.components_chat?.split(
          ","
        )
        : "";

    // Definimos los elementos que vamos a comparar
    const elementsToFind = [
      "check_query",
      "check_laser",
      "check_micro",
      "check_piercing",
      "check_barber",
      "check_gem_tooth",
      "check_microblading",
      "check_academy",
    ];

    // Buscamos el primer elemento que esté presente en el array components y concida con el array de elementsToFind, si encuentra la coincidencia lo metemos en la variable compareComponent, si no, dejamos la variable compareComponent vacia.
    const compareComponent =
      elementsToFind.find((element) => components?.includes(element)) || "";

    //Metemos en una variable llamada dataAll, la funcion DataAll que estamos exportando de FormChatComponents y como parametro de la funcion ponemos compareComponent para poder hacer los condicionales en dicha funcion y pasamos la variable dataAll a la comparativa que vamos hacer en filteredData
    let dataAll = DataAll(compareComponent);

    if (resp?.data?.result?.resultCustomFormComponents?.length !== 0 && 
      resp?.data?.result?.resultCustomFormComponents?.length !== undefined) {
      const filteredData =
        resp?.data?.result?.resultCustomFormComponents[0]?.components_chat
          .split(",")
          .map((component) =>
            dataAll.find((item) => item.component === component)
          )
          .filter((item) => item !== undefined);
      setArrayComponentInitial(filteredData);
      setComponents(filteredData);
    } else {
      setArrayComponentInitial(dataTattoo);

       setComponents(data);
    }
    if (
      resp?.data?.result?.resultCustomFormComponents[0]
        ?.components_chat_services !== null && resp?.data?.result?.resultCustomFormComponents[0]
        ?.components_chat_services !== undefined
    ) {
      
      const filteredData =
        resp?.data?.result?.resultCustomFormComponents[0]?.components_chat_services
          .split(",")
          .map((component) =>
            dataAll.find((item) => item.component === component)
          )
          .filter((item) => item !== undefined);
      setComponentsServices(filteredData);
    }

    if (
      resp.data?.result.resultUser[0].vip === 6 &&
      resp.data?.result.resultCustomFormStyles.length === 1
    ) {
      aplyClass(resp.data?.result.resultCustomFormStyles[0]);
    }
  };
  const handleSave = async () => {
    await saveBudget({
      id_user: user.id_user,
      budget_type: user.id_rol === 4 ? 2 : 1,
      formData,
    });
  };
  const handleSave2 = async () => {
    await saveBudget2({
      id_user: user.id_user,
      budget_type: user.id_rol === 4 ? 2 : 1,
      formData,
      notImages,
      setForm,
      arrayComponentInitial,
      arrayComponentServices,
      arrayComponent,
      setComponents,
      setErrorAlert,
      setStatus,
      setCheckTerms
    },
      getData,
      history, text);
  };
  const changeState = (message) => {
    setInitialButtons(true);
    setWelcomeMessage(message);
  };
  return (
    <>
      {loading ? (
        <PageLoading setLoading={setLoading} user={user} />
      ) : (
        <Routes>
          {(user.id_user === 1417) & (initialButtons === false) ||
            (user.id_user === 1165) & (initialButtons === false) ||
            (user.id_user === 1340) & (initialButtons === false) ||
            (user.id_user === 1472) & (initialButtons === false) ||
            (user.id_user === 1083) & (initialButtons === false) ? (
            <Route
              exact
              path="/"
              element={
                <InitialsButtons
                  phone={user.phone}
                  changeState={changeState}
                  setWelcomeMessage={setWelcomeMessage}
                />
              }
            />
          ) : (
            <Route
              exact
              path="/"
              element={
                sendSuccess === false ? (
                  <FormChat
                    status={status}
                    setStatus={setStatus}
                    errorAlert={errorAlert}
                    setErrorAlert={setErrorAlert}
                    error={error}
                    handleSave2={handleSave2}
                    language={language}
                    setLanguage={setLanguage}
                    user={user}
                    medias={medias}
                    text={text}
                    budget_color={budget_color}
                    setColor={setColor}
                    cities={cities}
                    budgetCount={budgetCount}
                    employeds={employeds}
                    typeJewelry={typeJewelry}
                    styles={styles[0]}
                    arrayComponent={arrayComponent}
                    arrayComponentInitial={arrayComponentInitial}
                    arrayComponentServices={arrayComponentServices}
                    initailOrImage={initailOrImage(user, medias, styles)}
                    setComponents={setComponents}
                    setForm={setForm}
                    formData={formData}
                    formDataInfo={formDataInfo}
                    setFormInfo={setFormInfo}
                    handleSave={handleSave}
                    data={
                      typeArray === 0 || arrayComponentServices.length === 0
                        ? arrayComponent
                        : arrayComponentServices
                    }
                    checkTerms={checkTerms}
                    setCheckTerms={setCheckTerms}
                    checkTermsAnotherQuery={checkTermsAnotherQuery}
                    setCheckTermsAnotherQuery={setCheckTermsAnotherQuery}
                    contact={contact}
                    setContact={setContact}
                    city={city}
                    setCity={setCity}
                    emailOrPhone={emailOrPhone}
                    setEmailOrPhone={setEmailOrPhone}
                    phoneCity={phoneCity}
                    setPhoneCity={setPhoneCity}
                    emailCity={emailCity}
                    setEmailCity={setEmailCity}
                    dataServices={formDataServices}
                    typeArray={typeArray}
                    setTypeArray={setTypeArray}
                    query={query}
                    setQuery={setQuery}
                    showPopover={showPopover}
                    setShowPopover={setShowPopover}
                    optionButtonServices={optionButtonServices}
                    setOptionButtonServices={setOptionButtonServices}
                    setSendSuccess={setSendSuccess}
                    setShowWriting={setShowWriting}
                    showWriting={showWriting}
                    disabledButtonSendEmail={disabledButtonSendEmail}
                    setDisabledButtonSendEmail={setDisabledButtonSendEmail}
                    disabledTextAreaAnotherQuery={disabledTextAreaAnotherQuery}
                    setDisabledTextAreaAnotherQuery={
                      setDisabledTextAreaAnotherQuery
                    }
                    notImages={notImages}
                    setNotImages={setNotImages}
                    welcomeMessage={welcomeMessage}
                  />
                ) : (
                  <NewTattoo
                    initailOrImage={initailOrImage(user, medias, styles)}
                    user={user}
                    styles={styles[0]}
                    medias={medias}
                    setShowWriting={setShowWriting}
                  />
                )
              }
            />
          )}
        </Routes>
      )}
    </>
  );
}
